<template>
  <div>
    <v-toolbar flat>
      <!-- <v-toolbar-title>{{ $t('title.user_list') }}</v-toolbar-title> -->
      <v-spacer></v-spacer>
      <v-col cols="2" sm="2" md="2" class="pl-2">
          <v-select v-if="userp.role_id == '611532b19c6e77ad0fd50c1c'"
            v-model="app_id"
            :items="getAllApps"
            item-value="_id"
            item-text="name"
            label="App Name #  "
            class="app-name"
            style="min-width: 100%;"
            @input="selectAppName(app_id)"
            outlined
            >
          </v-select>
        </v-col>
        <v-col cols="2" sm="2" md="2" class="pl-2">
          <v-select v-if="userp.role_id == '611532b19c6e77ad0fd50c1c'"
            v-model="role_id"
            :items="items"
            item-value="_id"
            item-text="name"
            label="Role #  "
            class="role-id"
            style="min-width: 100%;"
            @input="selectRole(role_id)"
            outlined
            >
          </v-select>
        </v-col>
      <div class="card-header-form">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="params.search"
            placeholder="Search"
          />
          <div class="input-group-btn">
            <button
              class="btn btn-primary"
              @click="fetchListUsers(params)"
              style="
                border-radius: 0px;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
              "
            >
              <i class="fas fa-search" style="border-radius: 0 !important"></i>
            </button>
          </div>
        </div>
      </div>
      <button
        class="ml-2 btn btn-primary"
        @click="onCreateDialog"
        v-if="checkUserPermission('CREATE')"
        style="border-radius: 50px"
      >
        <i class="fas fa-user-plus" style="border-radius: 0 !important"></i>
      </button>
    </v-toolbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["darkmode", "status", "params", "dataDelete", "userPermission","getAllApps","userp"],
  data() {
    return {
      data: {
        descendingFormat: [true],
        sortByFormat: ["_id"],
        descending: true,
        sortBy: null,
        page: 1,
        loader: false,
        rowsPerPage: 10,
        fields: [
          "app_id",
          "user_name",
          "balance",
          "account_info",
          "enable",
          "updatedAt",
        ],
        search: "",
        role_id : "all",
        app_id : "all",
      },
      role_id : "all",
      app_id : "all",
      search: "",
      dialogDelete: false,
      items: [
        { name: "All", _id: "all" },
        { name: "Company", _id: "612a3fcc1a1fcead8871e822" },
        { name: "Manager", _id: "612a40a51a1fcead8871e833" },
        { name: "Admin", _id: "61d9a2a869e14b72d45f25d0" },
        { name: "Controller", _id: "61bf5eb0c73d7eea8634fbf4" },
        { name: "Account", _id: "63f72742fc810f77b4bb30a6" },
      ],
    };
  },
  methods: {
    selectAppName(app_id){
      this.data.search = this.search;
      this.data.app_id = app_id;
      this.data.role_id = this.role_id;
     
      let data = {
        app_id: this.app_id,
        role_id: this.role_id
      }
      this.$emit("child-checkbox", data);
      // this.$router.go();
    },
    selectRole(role_id){
      this.data.search = this.search;
      this.data.role_id = role_id;
      this.data.app_id = this.app_id;
     
      let data = {
        app_id: this.app_id,
        role_id: this.role_id,
      }
      this.$emit("child-checkbox", data);
      // this.$router.go();
    },
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },

    async deleteItemConfirm() {
      await this.deleteUser({
        vm: this,
        data: { deleteItems: this.dataDelete },
      });
      this.fetchListUsers(this.params);
      this.dialogDelete = false;
    },

    async onCreateDialog() {
      await this.fetchAllRolesDependOnUser();
      this.fetchPopUp(true);
    },
    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_user", ["fetchPopUp", "fetchListUsers", "deleteUser"]),
  },
};
</script>

<style >
.input-group .v-input__slot {
  width: 100% !important;
  align-items: end !important;
  align-self: self-end;
}

.uneditable-input:focus,
input:focus,
input[type]:focus,
textarea:focus {
  box-shadow: 0px;
}

.input-group-text,
select.form-control:not([size]):not([multiple]),
.form-control:not(.form-control-sm):not(.form-control-lg) {
  font-size: 14px;
  padding: 5px 15px;
  height: 34px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.input-group-text,
select.form-control:not([size]):not([multiple]),
.form-control:not(.form-control-sm):not(.form-control-lg) {
  height: 36px !important;
}
</style>
